<div class="vertical-layout page-header-light vertical-menu-collapsible vertical-menu-nav-dark 2-columns mat-typography"
      data-open="click" data-menu="vertical-menu-nav-dark" data-col="2-column">
      <header class="page-topbar" id="header">
            <div class="navbar navbar-fixed">
                  <nav class="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light">
                        <div class="nav-wrapper">
                              <ul class="navbar-list right">
                                    <li><a class="waves-effect waves-block waves-light profile-button"
                                                href="javascript:void(0);" data-target="profile-dropdown"><span
                                                      class="avatar-status avatar-online"><img
                                                            src="https://oanhho.sohu.vn/image/logo.png"
                                                            alt="avatar"><i></i></span></a></li>
                                    <li><a class="waves-effect waves-block waves-light sidenav-trigger" href="#"
                                                data-target="slide-out-right"><i
                                                      class="material-icons">format_indent_increase</i></a></li>
                              </ul>
                              <ul class="dropdown-content" id="translation-dropdown">
                              </ul>
                              <ul class="dropdown-content" id="notifications-dropdown">
                              </ul>
                              <ul class="dropdown-content" id="profile-dropdown">
                              </ul>
                        </div>
                        <nav class="display-none search-sm">
                              <div class="nav-wrapper">
                              </div>
                        </nav>
                  </nav>
            </div>
      </header>
      <aside class="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-dark sidenav-active-rounded">
            <div class="brand-sidebar">
                  <h1 class="logo-wrapper"><a class="brand-logo darken-1" href="#" style="color:#009f3c;"><span
                                    class="logo-text hide-on-med-and-down" style="color:#009f3c;">Oanh Hồ</span></a><a
                              class="navbar-toggler" href="#"><i class="material-icons">radio_button_checked</i></a>
                  </h1>
            </div>
            <ul class="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
                  id="slide-out" data-menu="menu-navigation" data-collapsible="accordion">
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Report"><i
                                    class="material-icons">timeline</i><span class="menu-title" data-i18n="">Báo cáo
                                    thu chi</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}InvoiceOutput"><i
                                    class="material-icons">attach_money</i><span class="menu-title" data-i18n="">Bán
                                    hàng</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan "
                              href="{{domainName}}InvoiceOutputDetailHistory"><i
                                    class="material-icons">attach_money</i><span class="menu-title" data-i18n="">Sổ bán
                                    hàng</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}InvoiceInput"><i
                                    class="material-icons">airport_shuttle</i><span class="menu-title" data-i18n="">Nhập
                                    hàng</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}InvoiceInputDetailHistory"><i
                                    class="material-icons">airport_shuttle</i><span class="menu-title" data-i18n="">Sổ
                                    nhập hàng</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}InvoiceCost"><i
                                    class="material-icons">flag</i><span class="menu-title" data-i18n="">Chi
                                    phí</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}InvoiceCostDetailHistory"><i
                                    class="material-icons">flag</i><span class="menu-title" data-i18n="">Sổ
                                    chi phí</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}InvoiceSalary"><i
                                    class="material-icons">favorite_border</i><span class="menu-title"
                                    data-i18n="">Lương + ứng</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan "
                              href="{{domainName}}InvoiceSalaryDetailHistory"><i
                                    class="material-icons">favorite_border</i><span class="menu-title" data-i18n="">Sổ
                                    lương + ứng</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Customer"><i
                                    class="material-icons">person</i><span class="menu-title" data-i18n="">Bạn
                                    hàng</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Supplier"><i
                                    class="material-icons">directions_boat</i><span class="menu-title" data-i18n="">Chủ
                                    ghe</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Membership"><i
                                    class="material-icons">contact_phone</i><span class="menu-title" data-i18n="">Nhân
                                    viên</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Product"><i
                                    class="material-icons">bug_report</i><span class="menu-title" data-i18n="">Sản
                                    phẩm</span></a>
                  </li>
            </ul>
            <div class="navigation-background"></div><a
                  class="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only"
                  href="#" data-target="slide-out"><i class="material-icons">menu</i></a>
      </aside>
      <div id="main">
            <router-outlet></router-outlet>
      </div>
</div>