import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { InvoiceSalary } from 'src/app/shared/InvoiceSalary.model';
import { InvoiceSalaryService } from 'src/app/shared/InvoiceSalary.service';
import { Supplier } from 'src/app/shared/Supplier.model';
import { SupplierService } from 'src/app/shared/Supplier.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { InvoiceSalaryDetailComponent } from './invoice-salary-detail/invoice-salary-detail.component';

@Component({
  selector: 'app-invoice-salary',
  templateUrl: './invoice-salary.component.html',
  styleUrls: ['./invoice-salary.component.css']
})
export class InvoiceSalaryComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateCreated', 'TotalBeforeTax', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  total: number = environment.InitializationNumber;
  supplierID: number = environment.InitializationNumber;
  id: any;
  constructor(
    public InvoiceSalaryService: InvoiceSalaryService,
    public SupplierService: SupplierService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {    
    this.onSearch();
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }  
  GetToList() {
    this.isShowLoading = true;
    this.InvoiceSalaryService.GetByDateTimeBeginAndDateTimeEndAndSearchStringToListAsync(this.dateTimeBegin, this.dateTimeEnd, this.searchString).subscribe(
      res => {
        this.InvoiceSalaryService.list = res as InvoiceSalary[];
        if (this.InvoiceSalaryService.list) {
          this.dataSource = new MatTableDataSource(this.InvoiceSalaryService.list.sort((a, b) => (a.DateCreated > b.DateCreated ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.total = environment.InitializationNumber;
          for (let i = 0; i < this.InvoiceSalaryService.list.length; i++) {
            this.total = this.total + this.InvoiceSalaryService.list[i].TotalBeforeTax;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToList();
    }
  }
  onAdd(ID: any) {
    this.InvoiceSalaryService.GetByIDAsync(ID).subscribe(
      res => {
        this.InvoiceSalaryService.formData = res as InvoiceSalary;
        console.log(this.InvoiceSalaryService.formData);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(InvoiceSalaryDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetToList();
        });
      },
      err => {
      }
    );
  }
  onDelete(element: InvoiceSalary) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceSalaryService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
        }
      );
    }
  }
}
