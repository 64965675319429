<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Chi phí</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;       
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="InvoiceCostService.formData.ID" type="hidden">
    <input name="Name" [(ngModel)]="InvoiceCostService.formData.Name" type="hidden">
    <input name="TotalBeforeTax" [(ngModel)]="InvoiceCostService.formData.TotalBeforeTax" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="DateCreated">Ngày tạo</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <input name="DateCreated" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="InvoiceCostService.formData.DateCreated | date:'yyyy-MM-dd'"
                    (ngModelChange)="onChangeDateCreated($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">
            </div>
        </div>        
    </div>
    <div class="col s12 m12 l6">        
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="TotalBeforeTax">Thành tiền</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <div name="TotalBeforeTax" style="text-align: right; color: red; font-weight: bold; font-size: 20px;">
                    {{InvoiceCostService.formData.TotalBeforeTax | number:
                    '1.0-0'}}
                </div>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l12">
        <div class="KhungOverflow">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Note">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                        <input placeholder="Ghi chú" name="Note{{element.ID}}"
                            [(ngModel)]="element.Note" type="text">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Quantity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng (lần)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                        <input placeholder="0" name="Quantity{{element.ID}}" style="text-align: right;"
                            [(ngModel)]="element.Quantity" type="number">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Lương + ứng (đồng)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                        <input placeholder="0" name="Price{{element.ID}}" style="text-align: right;"
                            [(ngModel)]="element.Price" type="number">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Total">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tổng (đồng)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="width: 100%; text-align: right; font-weight: bolder;">
                        {{element.Total | number: '1.0-0'}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Save">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        &nbsp;&nbsp;&nbsp;
                        <a (click)="onSave(element)" class="btn-floating waves-effect waves-light cyan"><i
                                class="material-icons">save</i></a>&nbsp;&nbsp;&nbsp;
                        <a title="Xóa" class="btn-floating waves-effect waves-light red" (click)="onDelete(element)"><i
                                class="material-icons">delete_outline</i></a>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="4">
                        Đang tải dữ liệu...
                    </mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="4">
                        Chưa có dữ liệu.
                    </mat-footer-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['noData']"
                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                </mat-footer-row>
            </mat-table>
            <!-- <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                [showFirstLastButtons]></mat-paginator>                            -->
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>